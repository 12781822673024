import * as Sentry from '@sentry/browser';

export { Sentry };
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(window as any).Sentry = Sentry;

export function initSentry(dsn: string) {
	Sentry.init({
		dsn,
	});
}
